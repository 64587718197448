.write {
    padding-top: 50px;
    display: flex;
    width: 100%;
    margin-top: 60px;
}

.writeImg {
    margin-left: 15%;
    width: 70%;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}

.writeForm {
    margin-left: 15%;
    width: 70%;
}

.writeFormGroup {
    display: flex;
    align-items: center;
    width: 100%;
}

.writeFormGroup > textarea {
    height: 100px;
}

.writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(121, 118, 118);
    cursor: pointer;
}

.writeInput {
    font-size: 20px;
    border: none;
    padding: 20px;
    width: 100%;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    resize: none;
    font-family: sans-serif;
}

.writeInput:focus {
    outline: none;
}

.writeText {
    font-size: 20px;
    height: 100vh;
}

.writeSubmit {
    color: white;
    background-color: rgb(255, 105, 0);
    padding: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100px;
    margin-left: calc(50% - 100px);
}
