.link {
    text-decoration: none;
    color: black;
}

* {
    margin: 0;
    word-break: break-all;
    font-family: sans-serif;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.home {
    display: flex;
    width: 100%;
}

.btnHome {
    margin: 0 6px 0 43px;
    padding: 8px 12px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    background-color: rgb(255, 105, 0);
    color: white;
    user-select: none;
    border-radius: 9999px;
    text-align: center;
    font-family: sans-serif;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;

    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
}

.btnHome:hover {
    background-color: rgb(229, 98, 18);
    color: #fefefe;
}
