.singlePost {
    flex: 1;
    margin-top: 60px;
}

.singlePostWrapper {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.singlePostImg {
    width: 300px;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.singlePostTitle {
    text-align: center;
    margin: 10px;
    font-family: sans-serif;
    font-size: 28px;
}

.singlePostTitleInput {
    margin: 10px;
    font-family: sans-serif;
    font-size: 28px;
    text-align: center;
    border: none;
    color: gray;
    border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
    outline: none;
}

.singlePostEdit {
    float: right;
    font-size: 16px;
}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child {
    color: teal;
}

.singlePostIcon:last-child {
    color: tomato;
}

.singlePostInfo {
    width: 500px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: sans-serif;
    color: #b39656;
}

.singlePostDesc {
    color: #666;
    font-size: 18px;
    line-height: 25px;
}

.singlePostDescInput {
    color: #666;
    font-size: 18px;
    line-height: 25px;
    width: 500px;
    border-radius: 20px;
    padding: 10px;
    border-color: #bbb;
    resize: none;
    height: 100px;
}

.singlePostDescInput:focus {
    outline: none;
}

.singlePostButton {
    width: 100px;
    border: none;
    background-color: rgb(255, 105, 0);
    padding: 5px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}
