.header {
    width: calc(100% - 40px);
    border-radius: 50px;
    margin-left: 20px;
    margin-top: 80px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 470px;
    background-color: rgba(255, 105, 0, .9);
    backdrop-filter: saturate(180%) blur(20px);
    display: flex;
    margin-bottom: 20px;
}

.headerTitles {
    position: absolute;
    left: 20px;
    right: 470px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: sans-serif;
    color: #444;
}

.headerTitleSm {
    color: black;
    font-size: 50px;
}

.headerTitleLg {
    color: lightyellow;
    font-size: 60px;
}

.headerImg {
    width: 450px;
    height: 450px;
    margin-top: 10px;
    object-fit: cover;
    position: absolute;
    left: calc(100% - 460px);
}

.list {
    width: 250px;
    height: 160px;
    margin: 145px 0 0 120px
}

.list2 {
    width: 250px;
    height: 200px;
    margin: 310px 0 0 -70px
}

.pomodoro {
    width: 90px;
    height: 90px;
    margin: 210px 0 0 -100px;
}

.pomodoro2 {
    width: 70px;
    height: 67px;
    margin: -20px 0 0 10px;
}

.grib {
    width: 85px;
    height: 80px;
    margin: 80px 0 0 -90px;
}


@media screen and (max-width: 1237px) {
    .headerTitleSm {
        font-size: 40px;
    }

    .headerTitleLg {
        font-size: 50px;
    }
}

@media screen and (max-width: 1117px) {
    .headerTitles {
        margin-top: 120px;
    }

    .headerTitleSm {
        font-size: 30px;
    }

    .headerTitleLg {
        font-size: 40px;
    }
}

@media screen and (max-width: 1001px) {
    .headerTitles {
        margin-top: 150px;
    }

    .headerTitleSm {
        font-size: 20px;
    }

    .headerTitleLg {
        font-size: 30px;
    }
}

@media screen and (max-width: 912px) {
    .headerImg {
        left: calc(50% - 225px);
    }


    .header {
        height: 800px;
    }

    .headerTitles {
        left: calc(50% - 300px);
        right: 0;
        margin-top: 500px;
        width: 600px;
    }

    .headerTitleSm {
        font-size: 40px;
    }

    .headerTitleLg {
        font-size: 50px;
    }
}

@media screen and (max-width: 681px) {
    .headerImg {
        width: 200px;
        height: 200px;
        margin-top: 10px;
        object-fit: cover;
        position: relative;
        left: calc(50% - 100px);
    }

    .list {
        width: 110px;
        height: 70px;
        margin: 70px 0 0 60px;
        position: absolute;
    }

    .list2 {
        width: 110px;
        height: 90px;
        position: absolute;
        margin: 140px 0 0 -30px
    }

    .pomodoro {
        width: 40px;
        height: 40px;
        margin: 60px 0 0 -40px;
        position: absolute;
    }

    .pomodoro2 {
        width: 40px;
        height: 35px;
        margin: 90px 0 0 210px;
        position: absolute;
    }

    .grib {
        width: 45px;
        height: 40px;
        margin: 10px 0 0 180px;
        position: absolute;
    }

    .header {
        height: 500px;
    }

    .headerTitles {
        left: calc(50% - 200px);
        right: 0;
        margin-top: 250px;
        width: 400px;
    }

    .headerTitleSm {
        font-size: 20px;
    }

    .headerTitleLg {
        font-size: 30px;
    }
}

@media screen and (max-width: 400px) {
    .header {
        height: 440px;
    }

    .headerTitles {
        left: calc(50% - 150px);
        right: 0;
        margin-top: 230px;
        width: 300px;
    }

    .headerTitleSm {
        font-size: 10px;
    }

    .headerTitleLg {
        font-size: 20px;
    }
}

