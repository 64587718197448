.settings {
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    margin-bottom: 200px;
}

.settingsWrapper {

    padding: 20px;
    width: 400px;
}

.settingsTitle {
    display: flex;
    justify-content: center;
}

.settingsUpdateTitle {
    font-size: 30px;
    margin-bottom: 20px;
    color: rgb(255, 105, 0);
}

.settingsDeleteTitle {
    color: red;
    font-size: 12px;
    cursor: pointer;
}

.settingsForm {
    display: flex;
    flex-direction: column;
}

.settingsForm > label {
    font-size: 20px;
    margin-top: 20px;
}

.settingsForm > input {
    color: gray;
    margin: 10px 0;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgray;
}

.settingsForm > input:focus {
    outline: none;
}

.settingsSubmit {
    width: 150px;
    align-self: center;
    border: none;
    border-radius: 20px;
    color: white;
    background-color: rgb(255, 105, 0);
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
}

.settingsSubmit:hover {
    background-color: rgb(229, 98, 18);
    color: #fefefe;
}
