.login {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginTitle {
    font-size: 50px;
}

.loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm > label {
    margin: 10px 0;
}

.loginInput {
    padding: 10px;
    background-color: white;
    border: none;
}

.loginInput:focus {
    outline: none;
}

.loginButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: rgb(255, 105, 0);
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.loginButton:disabled {
    cursor: not-allowed;
    background-color: rgb(252, 173, 173);
}

.loginRegisterButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: rgb(255, 105, 0);
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}
