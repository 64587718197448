.register {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.registerTitle {
    font-size: 50px;
}

.registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.registerForm > label {
    margin: 10px 0;
}

.registerInput {
    padding: 10px;
    background-color: white;
    border: none;
}

.registerInput:focus {
    outline: none;
}


.registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: rgb(255, 105, 0);
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: rgb(255, 105, 0);
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}
