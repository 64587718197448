.basket {
    max-width: 430px;
    width: 100%;
}

.basketInfo {
    display: flex;
    margin-bottom: 12px;
}

.basketProduct {
    padding: 12px 16px 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}


.basketImg {
    width: 60.8px;
    height: 60.8px;
    margin: 3.2px 16px 0 3.2px;
    object-fit: cover;
    border-radius: 7px;
}


.basketTitle {
    margin: 0 0 4px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

.basketDesc {
    margin-bottom: 4px;
    font-family: sans-serif;
    color: rgb(92, 99, 112);
    font-size: 14px;
    line-height: 26px;
    cursor: pointer;
    font-weight: 400;
    border: none;
}

.basketPrice {
    font-family: sans-serif;
    padding-right: 4px;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
    font-weight: 500;
    width: 127px
}

.btnBasket {
    margin: 2px 0 2px calc(100% - 96px - 131px);
    height: 32px;
    width: 96px;
    line-height: 32px;
    font-size: 16px;
    background-color: rgb(255, 105, 0);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 9999px;
    text-align: center;
    font-family: sans-serif;
    font-weight: 500;

    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
}

.btnBasket:hover {
    background-color: rgb(229, 98, 18);
    color: #fefefe;
}

.basket-error-text {
    width: 270px;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 20px;
    text-align: center;
}